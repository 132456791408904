* {
  font-family: "Raleway" !important;
}
/* .react-calendar__tile:enabled:focus {
  background: #fff;
} */

.react-calendar__tile:not(.calendar-day-active) {
  background: #fff !important;
}

.react-calendar__tile:not(.calendar-day-active):hover {
  background-color: #fff !important;
}

.calendar-day-active {
  background: #d1fea4 !important;
  /* border: 1px solid #000 !important; */
  /* border-radius: 50%; */
}
.calendar-day-active abbr {
  color: #000 !important;
}

/* button
  .react-calendar__tile
  .react-calendar__tile--now
  .react-calendar__month-view__days__day
  .calendar-day
  .calendar-day
  .calendar-day-active {
  background: #d1fea4 !important;
  color: #000 !important;
} */

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.calendar-day.calendar-day-active {
  background: #d1fea4 !important;
  color: #000 !important;
}

button
  .react-calendar__tile
  .react-calendar__tile--now
  .react-calendar__month-view__days__day
  .calendar-day
  .calendar-day
  .calendar-day-active
  abbr {
  color: #000 !important;
}

.calendar-day-active {
  border: 1px solid #fff !important;
  background: #d1fea4 !important;
  color: #000 !important;
}

.calendar-day-selected {
  background: #ffff76 !important;
  color: #000 !important;
}
.calendar-day-weekend,
.react-calendar__tile:disabled *,
.calendar-day-weekend * {
  /* color: #f00 !important; */
  color: #e3e3e3 !important;
  cursor: not-allowed !important;
}

button.react-calendar__tile abbr {
  color: #000;
}

.react-calendar__tile--now {
  background: #fff !important;
  /* border-radius: 50%; */
  /* color: #fff; */
  border: 1px solid #e3e3e3 !important;
}

.react-calendar__tile--now abbr {
  color: #000 !important;
}

.react-calendar {
  color: #000;
  border-radius: 4px !important;
  border-color: #efefef !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 10%;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

@media screen and (max-width: 480px) {
  .react-calendar {
    width: 100% !important;
  }
}

@media screen and (min-width: 480px) {
  button.react-calendar__tile.react-calendar__month-view__days__day {
    height: 49.76px !important ;
  }
}

.react-calendar__tile:disabled {
  background-color: inherit !important;
  cursor: not-allowed !important;
}
